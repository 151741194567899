<!-- filter.component.html -->

<div class="filter-container">
  <app-search-box
    [filteredProductNames]="filteredDistinctNames"
    [placeholder]="searchBoxPlaceholder"
    (valueChanged)="onSearchTermChange($event)"
  ></app-search-box>

  <div class="subfilters">
    <div
      class="status-filter"
      [matMenuTriggerFor]="statusMenu"
      *ngIf="isAffiliateProductsListPage"
    >
      <mat-chip (click)="toggleStatusMenu()" class="status-menu">
        <div class="chip-content">
          {{ getDisplayStatusName(selectedStatus) | translate }}
          <mat-icon>{{
            statusMenuOpen ? "expand_less" : "expand_more"
          }}</mat-icon>
        </div>
      </mat-chip>

      <mat-menu
        #statusMenu="matMenu"
        [overlapTrigger]="false"
        class="status-container"
      >
        <mat-list>
          <mat-list-item
            *ngFor="let status of statusOptions"
            (click)="selectStatus(status)"
            [ngClass]="{ selected: selectedStatus === status }"
          >
            {{ getDisplayStatusName(status) | translate }}
          </mat-list-item>
        </mat-list>
      </mat-menu>
    </div>

    <div
      class="status-filter"
      [matMenuTriggerFor]="accessMenu"
      *ngIf="isEventTemplatesListPage"
    >
      <mat-chip (click)="toggleAccessMenu()" class="status-menu">
        <div
          class="chip-content"
          [ngClass]="{ 'ph-gray': selectedAccess === undefined }"
        >
          {{
            selectedAccess !== undefined
              ? (getDisplayAccessName(selectedAccess) | translate)
              : "Access"
          }}
          <mat-icon>{{
            accessMenuOpen ? "expand_less" : "expand_more"
          }}</mat-icon>
        </div>
      </mat-chip>

      <mat-menu
        #accessMenu="matMenu"
        [overlapTrigger]="false"
        class="status-container"
      >
        <mat-list>
          <mat-list-item
            *ngFor="let access of accessOptions"
            (click)="selectAccess(access)"
            [ngClass]="{ selected: selectedAccess === access }"
          >
            {{ getDisplayAccessName(access) | translate }}
          </mat-list-item>
        </mat-list>
      </mat-menu>
    </div>

    <div
      class="status-filter"
      [matMenuTriggerFor]="relevanceMenu"
      *ngIf="isEventTemplatesListPage"
    >
      <mat-chip (click)="toggleRelevanceMenu()" class="status-menu">
        <div
          class="chip-content"
          [ngClass]="{ 'ph-gray': selectedRelevance === undefined }"
        >
          {{
            selectedRelevance !== undefined
              ? (getDisplayRelevanceName(selectedRelevance) | translate)
              : "Relevance"
          }}
          <mat-icon>{{
            relevanceMenuOpen ? "expand_less" : "expand_more"
          }}</mat-icon>
        </div>
      </mat-chip>

      <mat-menu
        #relevanceMenu="matMenu"
        [overlapTrigger]="false"
        class="status-container"
      >
        <mat-list>
          <mat-list-item
            *ngFor="let relevance of relevanceOptions"
            (click)="selectRelevance(relevance)"
            [ngClass]="{ selected: selectedRelevance === relevance }"
          >
            {{ getDisplayRelevanceName(relevance) | translate }}
          </mat-list-item>
        </mat-list>
      </mat-menu>
    </div>

    @if (isEventTemplateAssetsListPage) {
      <div class="status-filter" [matMenuTriggerFor]="assetTypeMenu">
        <mat-chip (click)="toggleAssetTypeMenu()" class="status-menu">
          <div
            class="chip-content"
            [ngClass]="{ 'ph-gray': selectedAssetType === undefined }"
          >
            {{
              selectedAssetType !== undefined
                ? (getDisplayAssetTypeName(selectedAssetType) | translate)
                : ("APP.TYPE" | translate)
            }}
            <mat-icon>{{
              assetTypeMenuOpen ? "expand_less" : "expand_more"
            }}</mat-icon>
          </div>
        </mat-chip>

        <mat-menu
          #assetTypeMenu="matMenu"
          [overlapTrigger]="false"
          class="status-container"
        >
          <mat-list>
            <mat-list-item
              *ngFor="let assetType of assetTypeOptions"
              (click)="selectAssetType(assetType)"
              [ngClass]="{ selected: selectedAssetType === assetType }"
            >
              {{ getDisplayAssetTypeName(assetType) | translate }}
            </mat-list-item>
          </mat-list>
        </mat-menu>
      </div>
    }

    @if (isCampaignEventList) {
      <div class="status-filter" [matMenuTriggerFor]="eventCampaignStatusMenu">
        <mat-chip (click)="toggleeventCampaignStatusMenu()" class="status-menu">
          <div
            class="chip-content"
            [ngClass]="{ 'ph-gray': selectedEventCampaignStatus === undefined }"
          >
            {{
              selectedEventCampaignStatus !== undefined
                ? (EventCampaignStatus.getDisplayName(
                    selectedEventCampaignStatus
                  ) | translate)
                : ("APP.CAMPAIGNS.CAMPAIGN_STATUS" | translate)
            }}
            <mat-icon>{{
              eventCampaignStatusMenuOpen ? "expand_less" : "expand_more"
            }}</mat-icon>
          </div>
        </mat-chip>

        <mat-menu
          #eventCampaignStatusMenu="matMenu"
          [overlapTrigger]="false"
          class="status-container"
        >
          <mat-list>
            <mat-list-item
              *ngFor="let eventCampaignStatus of eventCampaignStatusOptions"
              (click)="selectEventCampaignStatus(eventCampaignStatus)"
              [ngClass]="{
                selected: selectedEventCampaignStatus === eventCampaignStatus
              }"
            >
              {{
                EventCampaignStatus.getDisplayName(eventCampaignStatus)
                  | translate
              }}
            </mat-list-item>
          </mat-list>
        </mat-menu>
      </div>
    }

    @if (
      !isPromptListPage &&
      !isCampaignListPage &&
      !isCampaignEventList &&
      !isUserListPage
    ) {
      <div class="category-filter">
        <mat-chip
          (click)="toggleCategoryMenu()"
          [ngClass]="{
            'selected-chip': categoryMenuOpen || selectedCategories.length
          }"
          class="chip-container"
        >
          <div class="chip-content">
            {{ "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CATEGORY" | translate }}
            {{
              selectedCategories.length > 0
                ? "(" + selectedCategories.length + ")"
                : ""
            }}
          </div>
        </mat-chip>

        <div
          [@categoryMenuAnimation]="categoryMenuOpen ? 'visible' : 'hidden'"
          class="category-menu"
          [class.display-none]="!categoryMenuOpen"
        >
          <div class="category-container">
            <mat-chip
              *ngFor="let category of categories"
              (click)="selectCategory(category)"
              [ngClass]="{
                'selected-chip': selectedCategories.includes(category)
              }"
              class="chip-container"
            >
              <div class="chip-content">
                {{ category?.name }}
              </div>
            </mat-chip>
          </div>
        </div>
      </div>

      <div class="subcategory-filter">
        <mat-chip
          (click)="toggleSubCategoryMenu()"
          [ngClass]="{
            'selected-chip': subCategoryMenuOpen || selectedSubcategories.length
          }"
          class="chip-container"
        >
          <div class="chip-content">
            {{ "APP.AFFILIATE_PRODUCTS_PAGE_LIST.SUBCATEGORY" | translate }}
            {{
              selectedSubcategories.length > 0
                ? "(" + selectedSubcategories.length + ")"
                : ""
            }}
          </div>
        </mat-chip>

        <div
          [@subCategoryMenuAnimation]="
            subCategoryMenuOpen ? 'visible' : 'hidden'
          "
          class="sub-category-menu"
          [class.display-none]="!subCategoryMenuOpen"
        >
          <div class="sub-category-container">
            <mat-chip
              *ngFor="let subCategory of subcategories"
              (click)="selectSubCategory(subCategory)"
              [ngClass]="{
                'selected-chip': selectedSubcategories.includes(subCategory)
              }"
              class="chip-container"
            >
              <div class="chip-content">
                {{ subCategory?.name }}
              </div>
            </mat-chip>
          </div>
        </div>
      </div>
    }

    <div class="partner-filter" *ngIf="isAffiliateProductsListPage">
      <mat-chip
        (click)="togglePartnerMenu()"
        [ngClass]="{
          'selected-chip': partnerMenuOpen || selectedPartners.length
        }"
        class="chip-container"
      >
        <div class="chip-content">
          {{ "APP.AFFILIATE_PRODUCTS_PAGE_LIST.PARTNER.LABEL" | translate }}
          {{
            selectedPartners.length > 0
              ? "(" + selectedPartners.length + ")"
              : ""
          }}
        </div>
      </mat-chip>

      <div
        [@partnerMenuAnimation]="partnerMenuOpen ? 'visible' : 'hidden'"
        class="partner-menu"
        [class.display-none]="!partnerMenuOpen"
      >
        <div class="partner-container">
          <mat-chip
            *ngFor="let partner of partners"
            (click)="selectPartner(partner)"
            [ngClass]="{ 'selected-chip': selectedPartners.includes(partner) }"
            class="chip-container"
          >
            <div class="chip-content">
              {{ getDisplayPartnerName(partner) | translate }}
            </div>
          </mat-chip>
        </div>
      </div>
    </div>
  </div>
</div>
